<template>
    <div class="login-without-password-page">
        <h1 class="login-without-password-page__title">
            {{ $lang.pages.inicioSinContrasena.login_without_password }}
        </h1>
        <AuthDialogEmailLoginStrategy class="login-without-password-page__form" :page-version="true" />
    </div>
</template>

<script lang="ts" setup>
import { useAuthStore } from '~/store/auth'

const AuthStore = useAuthStore()
const { $lang } = useNuxtApp()

if (!AuthStore.recoveryEmail) {
    navigateTo(`/${$lang.routes.forgotPassword}`, { redirectCode: 302 })
}

definePageMeta({
    middleware: ['not-auth-required'],
})
</script>

<style lang="postcss" scoped>
.login-without-password-page {
    @apply container pb-5 lg:pb-10;

    &__title {
        @apply mb-10 mt-5 text-center text-4xl lg:mt-10;
    }

    &__subtitle {
        @apply mb-3 text-center text-gray-800;
        a {
            @apply cursor-pointer text-site-primary hover:underline;
        }
    }
    &__form {
        @apply mx-auto max-w-[800px] overflow-hidden rounded-lg;
    }
}
</style>
